import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { PrivateRouter } from '../auth/PrivateRouter'
import { PublicRouter } from '../auth/PublicRouter'


/**Componentes de renderizado */
import { Login } from '../pages/login/Login'
import { Main } from './Main'


export const AuthRouter = () => {
    return (
        <BrowserRouter>
            <Routes>
                <Route path='/login' element={
                    <PublicRouter>
                        <Login />
                    </PublicRouter>
                } />

                <Route path='/*' element={
                    <PrivateRouter>
                        <Main />
                    </PrivateRouter>
                } />
            </Routes>
        </BrowserRouter>
    )
}
