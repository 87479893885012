import { Fragment } from 'react'
import { Route, Routes } from 'react-router-dom'
import { Users } from '../pages/usuarios/Users'



export const AppRouter = () => {
  return (
    <Fragment> 
        <Routes>
            <Route path='/' element={<Users /> } />
        </Routes>
    </Fragment>
  )
}
