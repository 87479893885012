import { Fragment, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import axios from 'axios';
/** Iconos */
import { PencilIcon, PencilAltIcon } from '@heroicons/react/solid'
/**Toast */
import { toast } from 'react-toastify';
import { toastOptions } from '../../constants/helpers';
/**Tailwinds liberias */
import { Dialog, Transition, Switch } from '@headlessui/react';
/**endpoint */
import { endpoint } from '../../constants/endpoint';

export const ModalsEditarUsuario = ({ data, searchUsers }) => {

    /**obtener el token */
    const { token } = JSON.parse(localStorage.getItem('user'));
    /**Manejo de dato en los inputs y el submit */
    const { register, handleSubmit, formState: { errors } } = useForm();
    /**Manejo del Loading */
    const [cargando, setCargando] = useState(false);
    /**Obtener las parroquias y sectores  */
    const [parroquias, setParroquias] = useState([]);
    /** mi sector */
    const [sectores, setSectores] = useState([]);
    const [open, setOpen] = useState(false)

    /**Estado del check */
    const [llamado, setLlamado] = useState(data.llamado)


    const getParroquiasSectores = async () => {
        setCargando(true)
        const url = `${endpoint}/sector/?format=json`;
        await axios.get(url, {
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`
            },
        }).then((response) => {
            setParroquias(response.data)
            let sectores = response.data.find(n => n.parroquia_id === data.id_parroquia);
            setSectores(sectores.data);
            setCargando(false)
        }).catch((err) => {
            console.log(err, ' aqui esta el error ')
        })
    }

    useEffect(() => {
        if (open === true) {
            getParroquiasSectores();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open]);


    const onSubmit = async (value) => {
        setCargando(true)
        const url = `${endpoint}/cliente/${data.id}/`
        await axios.put(url, value, {
            headers: {
                'Content-Type': 'application/json; charset=UTF-8',
                'Authorization': `Token ${token}`
            }
        }).then(() => {
            searchUsers();
            toast.success(`El usuario ${value.name} fue editado con exito!`, toastOptions);
            setCargando(false)
            setOpen(false)
        }).catch((err) => {
            console.log(err.response.data)
            // const error = validError(err.response.data.message)
            toast.warning(`Opps, Ha ocurrido un error, ${err.response.data.message}`, toastOptions);
            setCargando(false)
        })
    }

    const validarLlamada = async () => {
        let dataLlamada = {
            cliente: data.id,
            llamado: llamado
        }
        const url = `${endpoint}/cliente_llamado/`
        await axios.post(url, dataLlamada, {
            headers: {
                'Content-Type': 'application/json; charset=UTF-8',
                'Authorization': `Token ${token}`
            }
        }).then((res) => {
            toast.success(`${res.data}`, toastOptions)
            setOpen(false)
            searchUsers()
        }).catch(() => {
            toast.error(`Oops!, ha ocurrido un error`, toastOptions)
        })
    }

    const handleChange = (event) => {
        let sectores = parroquias.find(n => n.parroquia_id === event.target.value);
        setSectores(sectores.data);
    };

    return (
        <Fragment>
            <button
                type='button'
                onClick={() => setOpen(true)}>
                <PencilIcon className='h-6 w-6 text-black hover:text-yellow-500 ease-linear transition-all duration-100 flex-shrink-0' />
            </button>

            <Transition.Root show={open} as={Fragment}>
                <Dialog as='div' className='fixed z-10 inset-0 overflow-y-auto' onClose={setOpen}>
                    <div className='flex items-end justify-center max-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0'>
                        <Transition.Child
                            as={Fragment}
                            enter='ease-out duration-300'
                            enterFrom='opacity-0'
                            enterTo='opacity-100'
                            leave='ease-in duration-200'
                            leaveFrom='opacity-100'
                            leaveTo='opacity-0'
                        >
                            <Dialog.Overlay className='fixed inset-0 bg-black/60 transition-opacity' />
                        </Transition.Child>

                        {/* This element is to trick the browser into centering the modal contents. */}
                        <span className='hidden sm:inline-block sm:align-middle sm:h-screen' aria-hidden='true'>
                            &#8203;
                        </span>
                        <Transition.Child
                            as={Fragment}
                            enter='ease-out duration-300'
                            enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
                            enterTo='opacity-100 translate-y-0 sm:scale-100'
                            leave='ease-in duration-200'
                            leaveFrom='opacity-100 translate-y-0 sm:scale-100'
                            leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
                        >

                            <div className='inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-5xl sm:w-full'>
                                {/* Titulo */}
                                <Dialog.Title
                                    as='h3'
                                    className='text-lg leading-6 text-black pl-5 pb-2 pt-4'
                                >
                                    Editar - <span className='font-bold'>{data.name}</span>
                                </Dialog.Title>
                                <div className='border w-full mb-2 shadow-lg text-gray' />

                                {cargando === true ? (
                                    <div className=' flex justify-center items-center p-16'>
                                        <div className='animate-spin rounded-full h-32 w-32 border-b-2 border-gray-400'></div>
                                    </div>
                                ) : (
                                    <div className='bg-white px-4 pt-3 pb-4 sm:p-6 sm:pb-4'>
                                        <form onSubmit={handleSubmit(onSubmit)}>
                                            <div className='grid grid-cols-6 gap-6'>
                                                <div className='col-span-6 sm:col-span-2'>
                                                    <label htmlFor='telefono' className='text-black font-bold text-sm'>Teléfono <span className='text-red-900'>(*)</span> </label>
                                                    <input
                                                        id='telefono'
                                                        type='number'
                                                        autoComplete='off'
                                                        className='mt-1 focus:ring-transparent focus:border-gray-400 block py-2 px-3 w-full shadow sm:text-sm border-gray-300 rounded-md'
                                                        placeholder='Teléfono del usuario'
                                                        defaultValue={data.phone}
                                                        {...register('phone', { required: true })}
                                                    />
                                                    {errors.phone && (
                                                        <div className='text-red-800 pt-1 text-sm'>El campo nombre es requerido</div>
                                                    )}
                                                </div>

                                                <div className='col-span-6 sm:col-span-2'>
                                                    <label htmlFor='email' className='text-black font-bold text-sm'>Correo electrónico <span className='text-red-900'>(*)</span> </label>
                                                    <input
                                                        id='email'
                                                        autoComplete='off'
                                                        className='mt-1 focus:ring-transparent focus:border-gray-400 block py-2 px-3 w-full shadow sm:text-sm border-gray-300 rounded-md'
                                                        placeholder='Correo electrónico del usuario'
                                                        defaultValue={data.email}
                                                        {...register('email', {
                                                            required: true,
                                                            pattern: {
                                                                value: /^\w+([-]?\w+)*@\w+([-]?\w+)*(\.\w{2,3})+$/,
                                                                message: 'No tiene formato de correo valido - Ej. ejemplo@gmail.com'
                                                            }
                                                        })}
                                                    />
                                                    {errors.email && (
                                                        <Fragment>
                                                            {errors.email.type === 'required' && <div className='text-red-900 p-1 text-sm'>El correo es obligatorio</div>}
                                                            {errors.email.type === 'pattern' && <div className='text-red-900 p-1 text-sm'>{errors.email.message}</div>}
                                                        </Fragment>
                                                    )}

                                                </div>

                                                <div className='col-span-6 sm:col-span-2'>
                                                    <label htmlFor='nombre' className='text-black font-bold text-sm'>Nombre <span className='text-red-900'>(*)</span> </label>
                                                    <input
                                                        id='nombre'
                                                        type='text'
                                                        autoComplete='off'
                                                        {...register('name', { required: true })}
                                                        className=' mt-1 focus:ring-transparent focus:border-gray-400 block py-2 px-3 w-full shadow sm:text-sm border-gray-300 rounded-md'
                                                        placeholder='Nombre del usuario'
                                                        defaultValue={data.name}
                                                    />
                                                    {errors.name && (
                                                        <div className='text-red-800 pt-1 text-sm'>El campo nombre es requerido</div>
                                                    )}

                                                </div>
                                                {parroquias.length > 0 && (
                                                    <div className='col-span-6 sm:col-span-2'>
                                                        <label htmlFor='email' className='text-black font-bold text-sm'>Parroquia <span className='text-red-900'>(*)</span> </label>
                                                        <select
                                                            id='parroquia'
                                                            autoComplete='parroquia'
                                                            defaultValue={data.id_parroquia}
                                                            className='mt-2 block w-full py-2 px-3 border border-gray-300 bg-snow rounded-md shadow focus:outline-none focus:ring-transparent focus:border-gray-400 sm:text-sm'
                                                            {...register('parroquia', { required: true })}
                                                            onChange={handleChange}
                                                        >
                                                            <option value=''>Seleccione...</option>
                                                            {parroquias.map((item) => (
                                                                <option value={item.parroquia_id} key={item.parroquia_id}>
                                                                    {item.parroquia}
                                                                </option>
                                                            ))}
                                                        </select>
                                                        {errors.id_parroquia && (
                                                            <div className='text-red-800 pt-1 text-sm'>El campo item es requerido</div>
                                                        )}
                                                    </div>
                                                )}

                                                {sectores.length > 0 && (
                                                    <div className='col-span-6 sm:col-span-2'>
                                                        <label htmlFor='sector' className='text-black font-bold text-sm'>Sector <span className='text-red-900'>(*)</span> </label>
                                                        <select
                                                            id='sector'
                                                            autoComplete='sector'
                                                            defaultValue={data.id_sector}
                                                            className='mt-2 block w-full py-2 px-3 border border-gray-300 bg-snow rounded-md shadow focus:outline-none focus:ring-transparent focus:border-gray-400 sm:text-sm'
                                                            {...register('sector', { required: true })}
                                                        >
                                                            <option value=''>Seleccione...</option>
                                                            {sectores.map((item) => (
                                                                <option value={item.id} key={item.id}>
                                                                    {item.name}
                                                                </option>
                                                            ))}
                                                        </select>
                                                        {errors.id_sector && (
                                                            <div className='text-red-800 pt-1 text-sm'>El campo item es requerido</div>
                                                        )}
                                                    </div>
                                                )}

                                                <div className='col-span-6 sm:col-span-2'>
                                                    <Switch.Group>
                                                        <div className='px-2 mt-10 flex items-center'>
                                                            <Switch.Label className='mr-4 text-black text-sm'>Atendido <span className='text-ruby font-bold'>(*)</span> :</Switch.Label>
                                                            <Switch
                                                                checked={llamado}
                                                                onChange={setLlamado}
                                                                name='llamado'
                                                                className={`${llamado ? 'bg-black' : 'bg-gray-600'} relative inline-flex items-center h-6 rounded-full w-11 transition-colors`}
                                                            >
                                                                <span className={`${llamado ? 'translate-x-6' : 'translate-x-1'} inline-block w-4 h-4 transform bg-white rounded-full transition-transform`} />
                                                            </Switch>

                                                            {llamado ? (
                                                                <p className='ml-2'>
                                                                    <span className='inline-flex text-xs leading-5 font-semibold text-emerald tex'>
                                                                        Atendido
                                                                    </span>
                                                                </p>
                                                            ) : (
                                                                <p className='ml-2'>
                                                                    <span className='inline-flex text-xs leading-5 font-semibold text-ruby tex'>
                                                                        No atendido
                                                                    </span>
                                                                </p>
                                                            )}
                                                        </div>
                                                    </Switch.Group>

                                                </div>


                                            </div>


                                            <div className='mt-2 px-4 py-2 text-right sm:px-6'>

                                                <button
                                                    type='button'
                                                    onClick={() => setOpen(false)}
                                                    className='mr-2 inline-flex justify-center py-2 px-4 border border-transparent shadow text-sm font-bold rounded-md text-white bg-gray-600 hover:bg-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 ease-linear transition-all duration-100'
                                                >
                                                    Cancelar
                                                </button>
                                                <button
                                                    type='button'
                                                    onClick={() => validarLlamada()}
                                                    className='mr-2 inline-flex justify-center py-2 px-4 border border-transparent shadow text-sm font-bold rounded-md text-white bg-green-600 hover:bg-green-900 focus:outline-none focus:ring-2 focus:ring-offset-2 ease-linear transition-all duration-100'
                                                >
                                                    Validar atención
                                                </button>
                                                <button
                                                    type='submit'
                                                    className='inline-flex justify-center py-2 px-4 border border-transparent shadow text-sm font-bold rounded-md text-white bg-blue-600 hover:bg-blue-900 focus:outline-none focus:ring-2 focus:ring-offset-2 ease-linear transition-all duration-100'
                                                >
                                                    <span className='mr-1'>Editar </span> <PencilAltIcon className='h-4 w-4 mt-0.5' />
                                                </button>
                                            </div>
                                        </form>
                                    </div>
                                )}
                            </div>
                        </Transition.Child>
                    </div>
                </Dialog>
            </Transition.Root>
        </Fragment>
    )
}
