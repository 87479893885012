import { useEffect, useReducer } from 'react';


/**Funciones globales */
import { AuthContext } from './auth/authContext';
import { AuthRouter } from './routers/AuthRouter';
import { authReducer } from './auth/authReducer';


/** Toast */
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const init = () => {
    return JSON.parse(localStorage.getItem('user')) || { logged: false };
}

export const NetworkAdmin = () => {

    const [user, dispatch] = useReducer(authReducer, {}, init);

    useEffect(() => {
        if (!user) return;
        localStorage.setItem('user', JSON.stringify(user));
    }, [user])



    return (
        <AuthContext.Provider value={{ user, dispatch }}>
            <AuthRouter />
            <ToastContainer />
        </AuthContext.Provider>
    )
}
