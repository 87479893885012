import axios from 'axios'
import { Fragment, useContext, useState } from 'react'
import { useForm } from 'react-hook-form'
/**Liberias de Tailwinds */
import { Transition } from '@headlessui/react'
/**Toast */
import { toast } from 'react-toastify'
import { toastOptions } from '../../constants/helpers'
/**Auth archivos */
import { AuthContext } from '../../auth/authContext'
import { types } from '../../types/types'
/**endpoint */
import { endpoint } from '../../constants/endpoint'
/**Imagenes */
import { images } from '../../constants/images'



export const Login = () => {

  /**validacion de formulario y submit */
  const { register, handleSubmit, formState: { errors } } = useForm();
  /**disparar la action */
  const { dispatch } = useContext(AuthContext);
  /**Estado de Cargando */
  const [cargando, setCargando] = useState(false)
  /**Estado del formulario */
  const [segundoCargando, setSegundoCargando] = useState(true)

  const onSubmit = async (data) => {
    setCargando(true)
    const url = `${endpoint}/login/`;
    await axios.post(url, data).then((response) => {
      setTimeout(() => {
        const action = {
          type: types.login,
          payload: {
            user: response.data.user,
            token: response.data.token,
          }
        }
        dispatch(action)
      }, 1000)
      setCargando(false)
      setSegundoCargando(false)
      toast.success(`${response.data.message}`, toastOptions);
    }).catch((err) => {
      toast.warning(`Hubo un error, ${err.response.data.error}`, toastOptions);
      setCargando(false)
      setSegundoCargando(true)
    })
  }




  return (
    <Fragment>
      <div className='min-h-screen bg-black flex flex-col justify-center py-12 sm:px-6 lg:px-8'>
        {cargando === true ? (
          <Fragment>
            <div className='sm:flex sm:items-start justify-center items-center'>
              <img src={images.logoNetwork} alt='G-Network' className='animate-spin h-32 w-32' />
            </div>
          </Fragment>
        ) : (
          <Fragment>
            <Transition
              show={segundoCargando}
              enter="transition transform duration-500 zoom-in"
              enterFrom="translate-y-4 opacity-0"
              enterTo="translate-y-0 opacity-100"
              leave="transition-opacity duration-500"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className='sm:mx-auto sm:w-full sm:max-w-md'>
                <img
                  className='mx-auto h-12 w-auto'
                  src={images.logoNetwork3}
                  alt='G-Network'
                />
                <h2 className='mt-6 text-center text-3xl font-extrabold text-gray-100'>Panel administrativo</h2>
              </div>
              <div className='mt-8 sm:mx-auto sm:w-full sm:max-w-md'>
                <div className='bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10'>
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <div className='space-y-6'>
                      <div>
                        <label htmlFor='email' className='block text-sm font-medium text-gray-700'>
                          Correo Electrónico
                        </label>
                        <div className='mt-1'>
                          <input
                            id='email'
                            name='email'
                            type='email'
                            placeholder='Ingresa tu correo'
                            {...register('username', { required: true })}
                            className='appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm'
                          />
                          {errors.username && (
                            <div className='text-red-800 pt-1 text-sm'>El correo electrónico es requerido</div>
                          )}
                        </div>
                      </div>

                      <div>
                        <label htmlFor='password' className='block text-sm font-medium text-gray-700'>
                          Contraseña
                        </label>
                        <div className='mt-1'>
                          <input
                            id='password'
                            name='password'
                            type='password'
                            placeholder='Ingresa tu contraseña'
                            {...register('password', { required: true })}
                            className='appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm'
                          />
                          {errors.password && (
                            <div className='text-red-800 pt-1 text-sm'>La contraseña es requerido</div>
                          )}
                        </div>
                      </div>
                      <div>
                        <button
                          type='submit'
                          className='w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 ease-linear transition-all duration-300'
                        >
                          Entrar
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </Transition>
          </Fragment>
        )}
      </div>
    </Fragment>

  )
}