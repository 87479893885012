import { Fragment, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import axios from 'axios';
/** Iconos */
import { PlusIcon, UserIcon } from '@heroicons/react/solid'
/**Toast */
import { toast } from 'react-toastify';
import { toastOptions, validError } from '../../constants/helpers';
/**Tailwinds liberias */
import { Dialog, Transition } from '@headlessui/react';
/**endpoint */
import { endpoint } from '../../constants/endpoint';
import { AutocompleteSector } from '../autocomplete/AutocompleteSector';

export const ModalsCreateUsuario = ({ open, setOpen, searchUsers }) => {
    /**obtener el token */
    const { token } = JSON.parse(localStorage.getItem('user'));
    /**Manejo de dato en los inputs y el submit */
    const { register, handleSubmit, formState: { errors } } = useForm();
    /** Estado de Cargando */
    const [cargando, setCargando] = useState(false);
    /**Obtener las parroquias y sectores  */
    const [parroquias, setParroquias] = useState([]);
    // const [buscarSector, setBuscarSector] = useState([])
    // /**Mostrar */
    // const [mostrar, setMostrar] = useState(false)

    const getParroquiasSectores = async () => {
        const url = `${endpoint}/sector2/?format=json`;
        await axios.get(url, {
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`,
            },
        }).then((response) => {
            setParroquias(response.data)
        }).catch((err) => {
            console.log(err, ' aqui esta el error ')
        })
    }

    useEffect(() => {
        getParroquiasSectores();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    /**Funcion de submit para el registro */
    const onSubmit = async (data) => {
        setCargando(true)
        const url = `${endpoint}/cliente/`
        await axios.post(url, data, {
            headers: {
                'Content-Type': 'application/json; charset=UTF-8',
                'Authorization': `Token ${token}`,
            }
        }).then(() => {
            toast.success(`El usuario ${data.name} fue creado con exito!`, toastOptions);
            setCargando(false)
            setOpen(false)
            searchUsers()
        }).catch((err) => {
            const error = validError(err.response.data)
            toast.warning(`Opps, Ha ocurrido un error, ${error}`, toastOptions);
            setCargando(false)
        })
    }

    // const handleChange = (event) => {
    //     // eslint-disable-next-line eqeqeq
    //     let sectores = parroquias.find(n => n.parroquia_id == event.target.value);
    //     setBuscarSector(sectores.data);
    //     setMostrar(true)
    // };


    return (
        <Transition.Root show={open} as={Fragment}>
            <Dialog as='div' className='fixed z-10 inset-0 overflow-y-auto' onClose={setOpen}>
                <div className='flex items-end justify-center max-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0'>
                    <Transition.Child
                        as={Fragment}
                        enter='ease-out duration-300'
                        enterFrom='opacity-0'
                        enterTo='opacity-100'
                        leave='ease-in duration-200'
                        leaveFrom='opacity-100'
                        leaveTo='opacity-0'
                    >
                        <Dialog.Overlay className='fixed inset-0 bg-black/60 transition-opacity' />
                    </Transition.Child>

                    {/* This element is to trick the browser into centering the modal contents. */}
                    <span className='hidden sm:inline-block sm:align-middle sm:h-screen' aria-hidden='true'>
                        &#8203;
                    </span>
                    <Transition.Child
                        as={Fragment}
                        enter='ease-out duration-300'
                        enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
                        enterTo='opacity-100 translate-y-0 sm:scale-100'
                        leave='ease-in duration-200'
                        leaveFrom='opacity-100 translate-y-0 sm:scale-100'
                        leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
                    >

                        <div className='inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-5xl sm:w-full'>
                            {/* Titulo */}
                            <Dialog.Title
                                as='h3'
                                className='inline-flex text-lg leading-6 text-black pl-5 pb-2 pt-4'
                            >
                                Nuevo usuario <UserIcon className='ml-1 h-6 w-5' />
                            </Dialog.Title>
                            <div className='border w-full mb-2 shadow-lg text-gray' />

                            {cargando === true ? (
                                <div className=' flex justify-center items-center p-16'>
                                    <div className='animate-spin rounded-full h-32 w-32 border-b-2 border-gray-400'></div>
                                </div>
                            ) : (
                                <div className='bg-white px-4 pt-3 pb-4 sm:p-6 sm:pb-4'>
                                    <form onSubmit={handleSubmit(onSubmit)}>
                                        <div className='grid grid-cols-6 gap-6'>

                                            <div className='col-span-6 sm:col-span-2'>
                                                <label htmlFor='telefono' className='text-black font-bold text-sm'>Teléfono <span className='text-red-900'>(*)</span> </label>
                                                <input
                                                    id='telefono'
                                                    type='number'
                                                    autoComplete='off'
                                                    className='mt-1 focus:ring-transparent focus:border-gray-400 block py-2 px-3 w-full shadow sm:text-sm border-gray-300 rounded-md'
                                                    placeholder='Teléfono del usuario'

                                                    {...register('phone', { required: true })}
                                                />
                                                {errors.phone && (
                                                    <div className='text-red-800 pt-1 text-sm'>El campo nombre es requerido</div>
                                                )}
                                            </div>

                                            <div className='col-span-6 sm:col-span-2'>
                                                <label htmlFor='email' className='text-black font-bold text-sm'>Correo electrónico <span className='text-red-900'>(*)</span> </label>
                                                <input
                                                    id='email'
                                                    type='email'
                                                    autoComplete='off'
                                                    className='mt-1 focus:ring-transparent focus:border-gray-400 block py-2 px-3 w-full shadow sm:text-sm border-gray-300 rounded-md'
                                                    placeholder='Correo electrónico del usuario'
                                                    {...register('email', { required: true })}
                                                />
                                                {errors.email && (
                                                    <div className='text-red-900 p-1 text-sm'>El correo es obligatorio</div>
                                                )}

                                            </div>

                                            <div className='col-span-6 sm:col-span-2'>
                                                <label htmlFor='nombre' className='text-black font-bold text-sm'>Nombre <span className='text-red-900'>(*)</span> </label>
                                                <input
                                                    id='nombre'
                                                    type='text'
                                                    autoComplete='off'
                                                    {...register('name', { required: true })}
                                                    className=' mt-1 focus:ring-transparent focus:border-gray-400 block py-2 px-3 w-full shadow sm:text-sm border-gray-300 rounded-md'
                                                    placeholder='Nombre del usuario'

                                                />
                                                {errors.name && (
                                                    <div className='text-red-800 pt-1 text-sm'>El campo nombre es requerido</div>
                                                )}

                                            </div>

                                            <div className='col-span-6 sm:col-span-2'>

                                            <label htmlFor='sector' className='text-black font-bold text-sm'>Sector - Parroquia <span className='text-red-900'>(*)</span> </label>
                                                <AutocompleteSector parroquias={parroquias} />
                                            </div>

                                            {/* {parroquias.length > 0 && (
                                                <div className='col-span-6 sm:col-span-2'>
                                                    <label htmlFor='email' className='text-black font-bold text-sm'>Parroquia <span className='text-red-900'>(*)</span> </label>
                                                    <select
                                                        id='parroquia'
                                                        autoComplete='parroquia'

                                                        className='mt-2 block w-full py-2 px-3 border border-gray-300 bg-snow rounded-md shadow focus:outline-none focus:ring-transparent focus:border-gray-400 sm:text-sm'
                                                        {...register('parroquia', { required: true })}
                                                        onChange={handleChange}
                                                    >
                                                        <option value=''>Seleccione...</option>
                                                        {parroquias.map((item) => (
                                                            <option value={item.parroquia_id} key={item.parroquia_id}>
                                                                {item.parroquia}
                                                            </option>
                                                        ))}
                                                    </select>
                                                    {errors.id_parroquia && (
                                                        <div className='text-red-800 pt-1 text-sm'>El campo item es requerido</div>
                                                    )}
                                                </div>
                                            )} */}

                                            {/* <Transition
                                                as={Fragment}
                                                show={mostrar}
                                                enter='transition ease-out duration-400'
                                                enterFrom='transform opacity-0 scale-95'
                                                enterTo='transform opacity-100 scale-100'
                                                leave='transition ease-in duration-75'
                                                leaveFrom='transform opacity-100 scale-100'
                                                leaveTo='transform opacity-0 scale-95'
                                            >
                                                {buscarSector.length > 0 && (
                                                    <div className='col-span-6 sm:col-span-2'>
                                                        <label htmlFor='sector' className='text-black font-bold text-sm'>Sector <span className='text-red-900'>(*)</span> </label>
                                                        <select
                                                            id='sector'
                                                            autoComplete='sector'

                                                            className='mt-2 block w-full py-2 px-3 border border-gray-300 bg-snow rounded-md shadow focus:outline-none focus:ring-transparent focus:border-gray-400 sm:text-sm'
                                                            {...register('sector', { required: true })}
                                                        >
                                                            <option value=''>Seleccione...</option>
                                                            {buscarSector.map((item) => (
                                                                <option value={item.id} key={item.id}>
                                                                    {item.name}
                                                                </option>
                                                            ))}
                                                        </select>
                                                        {errors.id_sector && (
                                                            <div className='text-red-800 pt-1 text-sm'>El campo item es requerido</div>
                                                        )}
                                                    </div>
                                                )}

                                            </Transition> */}
                                        </div>

                                        <div className='mt-2 px-4 py-2 text-right sm:px-6'>
                                            <button
                                                type='button'
                                                onClick={() => setOpen(false)}
                                                className='mr-2 inline-flex justify-center py-2 px-4 border border-transparent shadow text-sm font-bold rounded-md text-white bg-gray-600 hover:bg-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 ease-linear transition-all duration-100'
                                            >
                                                Cancelar
                                            </button>
                                            <button
                                                type='submit'
                                                className='inline-flex justify-center py-2 px-4 border border-transparent shadow text-sm font-bold rounded-md text-white bg-blue-600 hover:bg-blue-900 focus:outline-none focus:ring-2 focus:ring-offset-2 ease-linear transition-all duration-100'
                                            >
                                                <span className='mr-1'>Crear </span> <PlusIcon className='h-4 w-4 mt-0.5' />
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            )}
                        </div>
                    </Transition.Child>
                </div>
            </Dialog>
        </Transition.Root>
    )
}
