export const SearchComponent = (props) => {
    const { divClassName = '', inputClassName = '', onChange, placeholder } = props
    return (
        <div className={`flex, ${divClassName}`}>
            <input
                type="text"
                name="search"
                id="search"
                placeholder={placeholder}
                onChange={onChange}
                className={`shadow-sm block md:w-96 w-full py-2 px-3 sm:text-sm border-gray-300 rounded-md focus:outline-none focus:border-gray-400 focus:ring-transparent ${inputClassName}`}
            />            
        </div>
    );
}