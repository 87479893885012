import { Fragment } from 'react'

/**Archivo de rutas */
import { AppRouter } from './AppRouter'


export const Main = () => {
    return (
        <Fragment>
            <AppRouter />
        </Fragment>
    )
}
