import axios from 'axios';
import { Fragment, useContext, useEffect, useState } from 'react';
/**Archivos de autentificacion */
import { AuthContext } from '../../auth/authContext';
import { types } from '../../types/types';
/**imagenes */
import { images } from '../../constants/images';
/**Endpoint */
import { endpoint } from '../../constants/endpoint';
/**iconos */
import { MailIcon, PhoneIcon, PlusIcon } from '@heroicons/react/solid'
import { LogoutIcon } from '@heroicons/react/outline';
/**componentes */
import { SearchComponent } from '../../components/inputs/SearchComponent';
import { Loading } from '../../components/loading/Loading';
import { ModalsEditarUsuario } from '../../components/modals/ModalsEditarUsuario';
import { ModalsCreateUsuario } from '../../components/modals/ModalsCreateUsuario';
/**Toast */
import { toast } from 'react-toastify';
import { toastOptions } from '../../constants/helpers';



export const Users = () => {

    /** manejar los valores user y dispatch*/
    const { dispatch } = useContext(AuthContext);
    /**obtener el token */
    const { token } = JSON.parse(localStorage.getItem('user'));
    /**Estado de cargar */
    const [cargando, setcargando] = useState(false);
    /**Busqueda */
    const [search, setSearch] = useState('');
    /**Total */
    const [total, setTotal] = useState([]);
    /**obtener los usuarios */
    const [users, setUsers] = useState([]);
    /**Paginado infinito */
    const [visibilidad, setVisibilidad] = useState(12);
    /**Modal de create */
    const [open, setOpen] = useState(false)


    const getUsers = async () => {
        setcargando(true)
        const url = `${endpoint}/cliente/`;
        await axios.get(url, {
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`,
            },
        }).then((response) => {
            setUsers(response.data.results)
            setTotal(response.data.count)
            setcargando(false)
        }).catch((err) => {
            console.log(err, ' aqui esta el error ')
            toast.warning(`Hubo un error, ${err.response.data.error}`, toastOptions);
            setcargando(false)
        })
    }

    const searchUsers = () => {
        setTimeout(async () => {
            setcargando(true)
            let url = `${endpoint}/cliente_search/?search=${search}`;
            await axios.get(url, { headers: { 'Authorization': `Token ${token}` } }).then((value) => {
                setUsers(value.data)
                setTotal(value.data.length)
                setcargando(false)
            }).catch((err) => {
                console.log(err, 'hay un error')
                setcargando(false)
            })
        }, 500);
    }

    useEffect(() => {
        if (search.trim().length > 3) { searchUsers(); }

        if (search.length < 1) { getUsers(); }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [search]);


    useEffect(() => {
        getUsers();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    /**Logout action para salir */
    const handlesLogout = async () => {
        const url = `${endpoint}/logout/`;
        /**Obtener el Token */
        const { token } = JSON.parse(localStorage.getItem('user'));
        await axios.get(url, {
            headers: { 'Authorization': `Token ${token}`, }
        }).then((response) => {
            dispatch({ type: types.logout })
            toast.success(`${response.data.session_message}`, toastOptions);
        }).catch((err) => {
            console.log(err)
        })
    }

    const classNames = (...classes) => {
        return classes.filter(Boolean).join(' ')
    }

    return (
        <Fragment>
            {cargando === true && (<Loading cargando={cargando} />)}
            <div className='flex flex-col p-6 font-sans bg-black'>
                <div className='-my-2 overflow-x-hidden sm:-mx-6 lg:-mx-8'>
                    <div className='p-4'>

                        <img
                            src={images.logoNetwork4}
                            alt='G-Network'
                            className='text-center h-fit w-fit mb-1 p-4 '
                        />
                        <div className='p-6 absolute top-0 right-0'>
                            <button
                                onClick={handlesLogout}
                                className='inline-flex py-2 px-6 rounded-full shadow-lg text-white bg-red-700 hover:bg-red-700/80 ease-linear transition-all duration-300'
                            >
                                Salir <LogoutIcon className='h-6 w-6 ml-1' />
                            </button>
                        </div>

                        <hr />
                    </div>



                    <div className='align-middle inline-block min-w-full sm:px-6 lg:px-8 '>
                        <div className='pb-5 grid grid-flow-col grid-cols-6 grid-rows row-auto gap-6'>

                            <div className='col-span-6 sm:col-span-2'>
                                <SearchComponent
                                    onChange={(event) => setSearch(event.target.value)}
                                    divClassName={'mt-3 mb-4'}
                                    placeholder='Buscar usuario ... '
                                />
                                <div className='mt-2 mb-1 inline-flex py-2 px-4 border border-transparent shadow text-sm rounded-md text-white bg-red-600 hover:bg-red-700 ease-linear transition-all duration-50'>
                                    Total registrados: <span className='ml-2 font-bold'>{total}</span>
                                </div>
                            </div>

                            <div className='lg:col-end-8 md:col-end-8 sm:col-end-8'>
                                <div className='w-28'>
                                    <button
                                        onClick={() => setOpen(true)}
                                        className='inline-flex py-2 px-4 mt-4 mr-4 rounded-full shadow-lg text-black bg-white hover:bg-white/80 ease-linear transition-all duration-300'
                                    >
                                        <div className='mr-2'>Nuevo</div> <PlusIcon className='h-6 w-5 text-green-400' />
                                    </button>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>



                <Fragment>

                    <ul className='grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-4'>
                        {users.slice(0, visibilidad).map((item) => (
                            <li key={item.id} className={classNames(
                                item.llamado === false ? 'bg-white' : 'bg-white/80',
                                'col-span-1  rounded-lg shadow divide-y divide-gray-200')}>
                                <div className='w-full flex items-center justify-between p-6 space-x-6'>
                                    <div className='flex-1 truncate'>
                                        <div className='flex items-center space-x-3'>
                                            <h3 className='text-gray-900 text-base font-medium truncate'>{item.name}</h3>
                                        </div>
                                        <p className='mt-1 text-gray-500 text-base truncate'>{item.nombre_parroquia} - {item.nombre_sector}</p>
                                        <div className='mt-1 text-gray-400 text-xs truncate'>{item.created_at}</div>
                                        <div className='mt-1 text-black text-xs font-bold truncate uppercase'>{item.llamado === true ? 'Atendido' : 'Por atender'}</div>
                                        {/* <div className='mt-1 text-gray-400 text-xs truncate'>{item.created_by === null ? (<span>{item.name}</span>) : (<span>{item.created_by}</span>)} - {item.created_at}</div> */}

                                    </div>
                                    {/* <img className='w-10 h-10 bg-gray-300 rounded-full flex-shrink-0' src={item.imageUrl} alt='' /> */}
                                    <ModalsEditarUsuario
                                        data={item}
                                        searchUsers={() => searchUsers()}

                                    />

                                </div>
                                <div>
                                    <div className='-mt-px flex divide-x divide-gray-200'>
                                        <div className='w-0 flex-1 flex'>
                                            <div
                                                className='break-all relative w-fit flex-1 inline-flex items-center justify-center px-1 py-4 text-xs text-gray-700 font-medium border border-transparent rounded-bl-lg hover:text-gray-500'
                                            >
                                                <MailIcon className='w-5 h-5 text-gray-400' aria-hidden='true' />
                                                {item.email === 'nullmail@email.com' ? (
                                                    <span className='ml-1'>No posee correo</span>
                                                ) : (
                                                    <span className='ml-1'>{item.email}</span>
                                                )}
                                            </div>
                                        </div>
                                        <div className='-ml-px w-0 flex-1 flex'>
                                            <div
                                                className='break-all relative w-fit flex-1 inline-flex items-center justify-center py-4 text-base text-gray-700 font-medium border border-transparent rounded-br-lg hover:text-gray-500'
                                            >
                                                <PhoneIcon className='w-5 h-5 text-gray-400' aria-hidden='true' />
                                                <span className='ml-1'>{item.phone}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                        ))}
                    </ul>
                    {visibilidad < users.length &&
                        <button
                            className='mt-10 inline-flex justify-center py-2 px-4 border border-transparent shadow text-sm font-bold rounded-md text-white bg-gray-900 hover:bg-gray-600 
                            focus:outline-none focus:ring-2 focus:ring-offset-2 ease-linear transition-all duration-100'
                            onClick={() => setVisibilidad(visibilidad + 12)}
                        >
                            Cargar mas...
                        </button>
                    }
                </Fragment>

                <ModalsCreateUsuario
                    open={open}
                    setOpen={setOpen}
                    searchUsers={searchUsers}
                />

            </div>
        </Fragment>
    )
}
